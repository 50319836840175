import { elb } from '@elbwalker/walker.js';

import { LineItem, Order } from './types';
import { SUMUP_ONE_SKU } from './constants';

import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { sendEvent } from '~/shared/services/analytics';
import { sendEvent as sendPythiaEvent } from '~/shared/services/metrics';
import { trackEvent } from '~/shared/services/optimizely/optimizely-browser-client';

export const trackAddToCart = ({
  lineItem,
  locale,
}: {
  lineItem: LineItem;
  locale: string;
}) => {
  const country = getCountryCodeFromISOLocale(locale);

  sendNinetailedEvent('addToCart');
  trackEvent({
    eventName: 'addToCart',
    eventTags: {
      country,
      productName: lineItem.id,
    },
  });

  if (lineItem.skuCode === SUMUP_ONE_SKU) {
    trackEvent('sumup_one_addtocart');
  }

  elb('add_to cart', {
    currency: lineItem.currencyCode,
    value: lineItem.totalAmountFloat,
    items: [
      {
        item_id: lineItem.skuCode,
        item_name: lineItem.productId,
        price: lineItem.unitAmountFloat,
        quantity: lineItem.quantity.toString(),
      },
    ],
  } as unknown);

  sendEvent({
    'event': 'interaction',
    'target': 'Ecommerce',
    'target-properties': '',
    'action': 'addToCart',
    'ecommerce': {
      add: {
        products: [
          {
            name: lineItem.productId,
            price: lineItem.unitAmountFloat,
            quantity: lineItem.quantity.toString(),
          },
        ],
      },
    },
  });

  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'order_add_line_item',
        labels: {
          status: 'successful',
          country,
        },
      },
    ],
  });
};
export const trackAddToCartFail = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);

  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'order_add_line_item',
        labels: {
          status: 'failed',
          country,
        },
      },
    ],
  });
};

export const trackUpdateQuantity = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'change_product_quantity_in_order',
        labels: {
          status: 'successful',
          country,
        },
      },
    ],
  });
};
export const trackUpdateQuantityFail = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'change_product_quantity_in_order',
        labels: {
          status: 'failed',
          country,
        },
      },
    ],
  });
};

export const trackRemoveFromCart = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'remove_product_from_order',
        labels: {
          status: 'successful',
          country,
        },
      },
    ],
  });
};
export const trackRemoveFromCartFail = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'remove_product_from_order',
        labels: {
          status: 'failed',
          country,
        },
      },
    ],
  });
};

export const trackOrderCreate = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    tags: { country },
    counterMetrics: [
      {
        name: 'order_created',
        labels: {
          status: 'successful',
          country,
        },
      },
    ],
  });
};
export const trackOrderCreateFail = ({ locale }: { locale: string }) => {
  const country = getCountryCodeFromISOLocale(locale);
  sendPythiaEvent({
    counterMetrics: [
      {
        name: 'order_created',
        labels: {
          status: 'failed',
          country,
        },
      },
    ],
  });
};

export const trackGoToCart = ({ order }: { order: Order }) => {
  const orderTotal = order.lineItems.reduce(
    (acc, lineItem) => acc + lineItem.totalAmountFloat,
    0,
  );

  elb('go_to cart', {
    currency: order.currencyCode,
    value: orderTotal,
    items: order.lineItems.map((lineItem) => ({
      item_id: lineItem.skuCode,
      item_name: lineItem.productId,
      price: lineItem.unitAmountFloat,
      quantity: lineItem.quantity.toString(),
    })),
  } as unknown);

  const hasSumUpOne = order.lineItems.some(
    (lineItem) => lineItem.skuCode === SUMUP_ONE_SKU,
  );

  if (hasSumUpOne) {
    trackEvent('goToCartWithSumUpOne');
  }

  trackEvent('goToCart');
};

function getCountryCodeFromISOLocale(locale: string): string {
  return locale.split('-')[1].toUpperCase();
}
